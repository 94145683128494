<template>
	<div class="Purchase">
		<w-navTab titleText="服装采购"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="container">
			<div class="body">
				<div class="body-nav">
					<div class="body-nav-list">
						<div :class="['nav-list-item', {'nav-active' : navIndex == index}]"
							v-for="(item,index) in navList" @click="onClickNav(index)">
							{{item.title}}
						</div>
					</div>
				</div>
				<div class="body-content">
					<div class="body-content-list">
						<div class="content-list-item" v-for="(item,index) in purchaseList" @click="$router.push({ name: 'PurchaseFirm',params:{id: item.id,type: navIndex}})">
							<div class="list-item-img">
								<img :src="item.image" alt="">
							</div>
							<div class="list-item-right">
								<div class="item-right-title">
									{{item.name}}
								</div>
								<div class="item-right-text">
									联系电话：{{item.phone_number}}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Purchase from '@/api/purchase';
	import VantVendor from '@/vendor/vant';
	import LoadingPage from '@/components/LoadingPage';
	
	export default {
		name: 'Purchase',
		data() {
			return {
				navIndex: 0,
				loadingPage: true,
				navList: [{id: 2,title:'厂家直售'},{id: 3,title:'政府采购'}],
				purchaseList: [],
			}
		},
		created() {
			this.$store.commit('keep/setKeep', 'Purchase');
			this.getPurchaseNav()
		},
		methods: {
			getPurchaseNav(){
				
				let params ={
					type: this.navList[this.navIndex].id
				}
				
				Purchase.companiesList(params)
					.then(result => {
						this.purchaseList = result.data.list
						this.loadingPage = false
					})
					.catch(error => {
						console.log(error);
					});
			},
			onClickNav(index) {
				this.navIndex = index
				this.purchaseList = []
				this.getPurchaseNav()
			},
		},
		components: {
			VantVendor,
			LoadingPage,
		}
	}
</script>

<style scoped lang="less">
	.Purchase {
		width: 100%;
		height: 100vh;
		background: #F2F2F2;

		.container {

			.body {
				background: #F2F2F2;
				box-sizing: border-box;
				
				.body-nav {
					padding-bottom: 1px;
					background: #FFF;
					box-shadow: 0px -0.5px 0px 0px #E5E6EB inset;
					
					.body-nav-list {
						display: flex;
						justify-content: center;
						align-items: center;
						
						.nav-list-item {
							padding-top: 11px;
							color: #999;
							font-size: 15px;
							font-weight: bold;
							box-sizing: border-box;
							margin-right: 60px;
							
							&.nav-list-item:last-child{
								margin-right: 0;
							}
							
							&::after {
								content: '';
								display: block;
								width: 45px;
								height: 2px;
								background: #fff;
								margin: 8px auto 0;
							}
							
							&.nav-active {
								color: #333;

								&::after {
									content: '';
									display: block;
									margin-top: 8px;
									width: 45px;
									height: 2px;
									background: #37F;
									margin: 8px auto 0;
								}
							}
						}
					}
				}

				.body-content {
					margin: 16px 16px 0;
					
					.body-content-list {
						
						.content-list-item {
							margin-bottom: 12px;
							display: flex;
							justify-content: space-between;
							align-items: center;
							border-radius: 8px;
							padding: 12px;
							background: #FFF;
							box-sizing: border-box;
							
							&.content-list-item:last-child{
								margin-bottom: 0;
							}
							
							.list-item-img {
								width: 80px;
								height: 80px;
								min-width: 80px;
								margin-right: 12px;
								border-radius: 2px;
								border: 1px solid #E6E6E6;
								box-sizing: border-box;
								
								img {
									display: block;
									width: 100%;
									height: 100%;
									object-fit: cover;
									border-radius: 2px;
								}
							}

							.list-item-right {
								flex: 1;
								
								.item-right-title {
									height: 48px;
									color: #333;
									font-size: 18px;
									display: -webkit-box;
									-webkit-box-orient: vertical;
									-webkit-line-clamp: 2;
									overflow: hidden;
									line-height: 27px;
								}

								.item-right-text {
									margin-top: 8px;
									color: #999;
									font-size: 16px;
									line-height: 24px;
								}
							}
						}
					}
				}
			}
		}
	}
</style>